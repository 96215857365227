import React, { ChangeEvent, useContext } from 'react';
import { ThemeContext } from './ThemeContext';

export default function ThemeToggle({ id }: { id?: string }) {
  const { theme, setTheme } = useContext(ThemeContext);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const newTheme = e.target.checked ? 'dark' : 'light';
    setTheme(newTheme);
    document.body.setAttribute('data-theme', newTheme);
  }

  if (!theme) {
    return null;
  }

  return (
    <label htmlFor={id || 'theme-toggle'} className="theme-toggle">
      <input
        id={id || 'theme-toggle'}
        type="checkbox"
        checked={theme === 'dark'}
        onChange={handleChange}
        className="visually-hidden"
      />{' '}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="11"
        viewBox="0 0 13 11"
        fill="currentColor"
      >
        <g transform="translate(-3.5 -4.5)">
          <path d="M7,15.5,3.5,12,7,8.5V11h4v2H7Z" />
          <path d="M13,4.5,16.5,8,13,11.5V9H9V7h4Z" />
        </g>
      </svg>
      {theme === 'dark' ? 'Light Mode' : 'Dark Mode'}
    </label>
  );
}
