import { Link } from 'gatsby';
import React from 'react';
import { ResultProps } from './HeaderSearch';

interface Props {
  result: ResultProps;
  closeSearch: Function;
}

export default function HeaderSearchResult({ result, closeSearch }: Props) {
  return (
    <li key={`${result.id}`} className="search-results__entry post-entry">
      <Link
        to={result.uri || result.link}
        className="search-results__entry__inner"
        onClick={() => closeSearch()}
      >
        <p className="search-results__entry__title post-entry__title small">
          {result.title}
        </p>
      </Link>
    </li>
  );
}
