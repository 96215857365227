import { Link } from 'gatsby';
import React from 'react';
import { motion } from 'framer-motion';
import ThemeToggle from './ThemeToggle';

const variantsNav = {
  open: {
    opacity: 1,
    // pointerEvents: 'auto',
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 300,
      damping: 40,
      // restDelta: 2,
    },
  },
  closed: {
    opacity: 0,
    // pointerEvents: 'none',
    x: '-100%',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const variantsUl = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const variantsLi = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      stiffness: 1000,
      velocity: -100,
    },
    // transition: {
    //   y: { stiffness: 1000, velocity: -100 },
    // },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      stiffness: 1000,
      // y: { type: "spring", stiffness: 200 },
    },
  },
};

interface MobileMenuProps {
  menuOpen: boolean;
  setMenuOpen: Function;
}

interface Props {
  location: Location;
  isPartiallyCurrent: boolean;
}

export default function MobileMenu({ menuOpen, setMenuOpen }: MobileMenuProps) {
  function isPostArchiveTemplate({ location, isPartiallyCurrent }: Props) {
    const pathnames = location.pathname.split('/');
    return isPartiallyCurrent || pathnames.includes('category')
      ? { className: 'active' }
      : {};
  }

  return (
    <motion.nav
      className="menu mobile-menu"
      initial={false}
      animate={menuOpen ? 'open' : 'closed'}
      variants={variantsNav}
    >
      <motion.ul variants={variantsUl}>
        <motion.li variants={variantsLi}>
          <Link
            activeClassName="active"
            to="/"
            onClick={() => setMenuOpen(false)}
          >
            Home
          </Link>
        </motion.li>
        <motion.li variants={variantsLi}>
          <Link
            activeClassName="active"
            to="/services/"
            onClick={() => setMenuOpen(false)}
          >
            Services
          </Link>
        </motion.li>
        <motion.li variants={variantsLi}>
          <Link
            activeClassName="active"
            to="/case-studies/"
            onClick={() => setMenuOpen(false)}
          >
            Case Studies
          </Link>
        </motion.li>
        <motion.li variants={variantsLi}>
          <Link
            activeClassName="active"
            to="/latest/"
            onClick={() => setMenuOpen(false)}
            getProps={isPostArchiveTemplate}
          >
            Latest
          </Link>
        </motion.li>
        <motion.li variants={variantsLi}>
          <Link
            activeClassName="active"
            to="/about/"
            onClick={() => setMenuOpen(false)}
          >
            About Us
          </Link>
        </motion.li>
        <motion.li variants={variantsLi}>
          <Link
            activeClassName="active"
            to="/contact/"
            onClick={() => setMenuOpen(false)}
          >
            Contact
          </Link>
        </motion.li>
        <motion.li variants={variantsLi}>
          <ThemeToggle id="theme-toggle-menu" />
        </motion.li>
      </motion.ul>
    </motion.nav>
  );
}
