import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import React from 'react';

interface Props {
  location: Location;
  isPartiallyCurrent: boolean;
}

export default function Menu() {
  function isCaseStudyArchiveTemplate({ location, isPartiallyCurrent }: Props) {
    const pathnames = location.pathname.split('/');
    return isPartiallyCurrent ||
      pathnames.includes('case-study') ||
      pathnames.includes('case-studies')
      ? { className: 'active' }
      : {};
  }
  function isPostArchiveTemplate({ location, isPartiallyCurrent }: Props) {
    const pathnames = location.pathname.split('/');
    return isPartiallyCurrent || pathnames.includes('category')
      ? { className: 'active' }
      : {};
  }
  return (
    <nav className="menu">
      <ul>
        <li>
          <Link
            activeClassName="active"
            to="/"
            onClick={(e) => e.currentTarget.blur()}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            activeClassName="active"
            to="/services/"
            onClick={(e) => e.currentTarget.blur()}
          >
            Services
          </Link>
        </li>
        <li>
          <Link
            activeClassName="active"
            to="/case-studies/"
            getProps={isCaseStudyArchiveTemplate}
            onClick={(e) => e.currentTarget.blur()}
          >
            Case Studies
          </Link>
        </li>
        <li>
          <Link
            activeClassName="active"
            to="/latest/"
            getProps={isPostArchiveTemplate}
            onClick={(e) => e.currentTarget.blur()}
          >
            Latest
          </Link>
        </li>
        <li>
          <Link
            activeClassName="active"
            to="/about/"
            onClick={(e) => e.currentTarget.blur()}
          >
            About Us
          </Link>
        </li>
        <li>
          <Link
            activeClassName="active"
            to="/contact/"
            onClick={(e) => e.currentTarget.blur()}
          >
            Contact
          </Link>
          <ul className="submenu">
            <li>
              <AnchorLink
                to="/contact#subscribe"
                onAnchorLinkClick={() =>
                  (document.activeElement as HTMLElement)?.blur()
                }
              >
                Subscribe
              </AnchorLink>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
}
