import { Link } from 'gatsby';
import React from 'react';
import Colophon from './Colophon';
import Logo from './Logo';
import Menu from './Menu';
import ThemeToggle from './ThemeToggle';

export default function Footer() {
  return (
    <>
      <footer className="footer container">
        <div className="footer__inner push-right">
          <div className="footer__inner__wrap">
            <div className="footer__logo">
              <Logo />
            </div>
            <div className="footer__menu">
              <Menu />
            </div>
            <div className="footer__links">
              <ul>
                <li>
                  <Link to="/privacy-policy" className="small">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link to="/cookie-policy" className="small">
                    Cookie Policy
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__theme-toggle">
              <ThemeToggle />
            </div>
          </div>
        </div>
        <Colophon />
      </footer>
    </>
  );
}
