import React from 'react';
import { IoLogoLinkedin } from 'react-icons/io';

export default function Social() {
  return (
    <div className="social">
      <ul>
        <li>
          <a
            href="https://www.linkedin.com/company/pesch-strategy"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="visually-hidden">LinkedIn</span>
            <IoLogoLinkedin />
          </a>
        </li>
      </ul>
    </div>
  );
}
