import React from 'react';

export default function Colophon() {
  return (
    <a
      href="https://www.longbeard.com"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Built by Longbeard"
      className="colophon"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="997"
        height="565"
        viewBox="0 0 997 565"
      >
        <g>
          <path
            d="M16,204.293V25.261H60.232v140.6h88.993V204.3Z"
            transform="translate(47.533 50.449)"
          />
          <path
            d="M217.223,23.07c52.922,0,92.415,39.493,92.415,92.412,0,52.4-39.493,91.886-92.415,91.886-52.4,0-91.887-39.491-91.887-91.886C125.336,62.563,164.827,23.07,217.223,23.07Zm.264,142.7c28.96,0,48.708-21.852,48.708-50.287,0-28.96-19.748-50.551-48.708-50.551-28.173,0-48.709,21.591-48.709,50.551,0,28.435,20.536,50.287,48.709,50.287Z"
            transform="translate(69.581 50.007)"
          />
          <path
            d="M333.629,95.557V204.293H289.4V25.261h38.441L417.882,134V25.261h44.234V204.293H423.94Z"
            transform="translate(102.665 50.449)"
          />
          <path
            d="M617.15,98.9v83.459c-16.586,13.428-49.762,25.013-77.144,25.013-52.922,0-95.31-34.226-95.31-91.886,0-58.187,42.388-92.412,95.31-92.412,28.961,0,52.4,9.742,70.3,23.958L590.033,80.464C576.6,69.934,560.808,63.879,541.06,63.879c-28.7,0-50.551,18.693-50.551,51.6,0,32.383,21.852,51.077,52.395,51.077a72.46,72.46,0,0,0,32.394-7.64l-.011-26.316H532.372V98.9Z"
            transform="translate(133.981 50.007)"
          />
          <path
            d="M602.049,25.261H681.3c39.757,0,63.188,15.269,63.188,48.181,0,17.9-9.742,30.8-25.8,37.647,25.01,5.266,40.016,19.483,40.016,44.761,0,30.013-23.165,48.443-65.294,48.443H602.049Zm72.142,70.3c15.8,0,24.749-5.266,24.749-17.377,0-12.636-8.427-17.64-24.224-17.64H646.281V95.557Zm12.638,73.456c16.587,0,24.75-6.318,24.75-18.428,0-13.164-9.481-19.223-26.857-19.223H646.281v37.651Z"
            transform="translate(-538.515 287.625)"
          />
          <path
            d="M742.838,204.293V25.261H886.593V63.7H784.965V94.5h82.41v37.124h-82.41v34.23H886.593v38.437Z"
            transform="translate(-510.125 287.625)"
          />
          <path
            d="M939.247,25.261h51.87l67.926,179.032h-44.756l-12.114-32.912H927.137l-12.375,32.912H870ZM938.193,139h52.924l-25.8-70.034Z"
            transform="translate(-484.483 287.625)"
          />
          <path
            d="M1292.4,25.261h-80.31v140.73h-22.376c-16.585,0-32.386-13.039-48.444-27.781,27.116-5.53,46.865-25.8,46.865-53.974,0-41.863-33.965-58.975-68.722-58.975h-85.042V204.293H1078.6v-64.5h9.481c23.3,15.973,53.128,63.931,93.858,64.455l110.453.049c52.92,0,92.411-31.594,92.411-89.253C1384.808,56.853,1345.317,25.523,1292.4,25.261Zm-173.771,82.67H1078.6V63.7h40.02c14.481,0,23.695,8.425,23.695,22.115.005,13.164-9.209,22.116-23.69,22.116Zm171.927,58.059h-34.229V63.7h34.229c28.961,0,48.709,18.164,48.709,51.34,0,32.648-19.752,50.95-48.713,50.95Z"
            transform="translate(-451.336 287.625)"
          />
        </g>
      </svg>
    </a>
  );
}
