import { Link } from 'gatsby';
import React from 'react';

export default function Logo() {
  return (
    <Link to="/" aria-label="Pesch" className="logo">
      <svg
        width="166"
        height="81"
        viewBox="0 0 166 81"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20.0481 19.9841H29.4839C35.8909 19.9841 39.0362 23.5371 39.0362 30.0606V34.0796C39.0362 40.6031 35.8909 44.1561 29.4839 44.1561H26.4551V60.7619H20.0481V19.9841ZM29.4839 38.3315C31.5225 38.3315 32.6291 37.3996 32.6291 34.4873V29.6529C32.6291 26.7406 31.5225 25.8087 29.4839 25.8087H26.4551V38.3315H29.4839Z" />
        <path d="M47.1906 19.9841H64.6643V25.8087H53.5976V36.5841H62.3927V42.4087H53.5976V54.9373H64.6643V60.7619H47.1906V19.9841Z" />
        <path d="M72.0033 51.093V48.7632H78.0608V51.559C78.0608 54.4713 79.3422 55.5197 81.3808 55.5197C83.4194 55.5197 84.7008 54.4713 84.7008 51.4425C84.7008 48.0643 83.4194 45.9092 79.2258 42.2339C73.8672 37.516 72.1198 34.196 72.1198 29.5363C72.1198 23.1293 75.4398 19.4016 81.6721 19.4016C87.9044 19.4016 90.9914 23.1293 90.9914 29.6528V31.342H84.9338V29.2451C84.9338 26.3328 83.7689 25.2262 81.7303 25.2262C79.6917 25.2262 78.5268 26.3328 78.5268 29.1286C78.5268 32.0992 79.8665 34.2542 84.0601 37.9237C89.4187 42.6416 91.1079 45.9092 91.1079 50.9766C91.1079 57.6166 87.7296 61.3443 81.4391 61.3443C75.1486 61.3443 72.0033 57.6166 72.0033 51.093Z" />
        <path d="M99.0292 51.326V29.4199C99.0292 23.1293 102.291 19.4016 108.465 19.4016C114.639 19.4016 117.901 23.1293 117.901 29.4199V33.73H111.843V29.0121C111.843 26.3328 110.562 25.2262 108.64 25.2262C106.718 25.2262 105.436 26.3328 105.436 29.0121V51.792C105.436 54.4713 106.718 55.5197 108.64 55.5197C110.562 55.5197 111.843 54.4713 111.843 51.792V45.5539H117.901V51.326C117.901 57.6166 114.639 61.3443 108.465 61.3443C102.291 61.3443 99.0292 57.6166 99.0292 51.326Z" />
        <path d="M126.405 19.9841H132.812V36.5841H139.685V19.9841H146.092V60.7561H139.685V42.4087H132.812V60.7619H126.405V19.9841Z" />
        <path d="M166 80.676H0V0H166V80.676ZM6.40702 74.269H159.593V6.40702H6.40702V74.269Z" />
      </svg>
    </Link>
  );
}
