import React, { useEffect, useRef, useState } from 'react';
import { MdSearch } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { graphql, useStaticQuery } from 'gatsby';
import { AnimatePresence, motion } from 'framer-motion';
import useFlexSearch from '../hooks/useFlexSearch';
import useOnClickOutside from '../hooks/useOnClickOutside';
import HeaderSearchResult from './HeaderSearchResult';
import { PostProps } from './PostEntry';
import { CaseStudyProps } from './CaseStudyEntry';

export interface ResultProps extends PostProps, CaseStudyProps {
  nodeType: string;
  cleanContent?: string;
}

export default function HeaderSearch() {
  // Form
  const { register, watch, reset, setFocus } = useForm();

  const [mobileSearchOpen, setMobileSearchOpen] = useState(false);

  const searchForm = useRef<HTMLFormElement>(null);

  function handleClick(e: React.MouseEvent) {
    // let windowWidth: Number | null = null;
    // if (typeof window !== 'undefined') {
    //   windowWidth = window.innerWidth;
    // }
    // if (windowWidth && windowWidth < 768) {
    if (!mobileSearchOpen) {
      e.preventDefault();
      setMobileSearchOpen(true);

      setFocus('s');
    }
    // }
  }

  // Search
  const query = watch('s');
  const { localSearchPosts } = useStaticQuery(graphql`
    query {
      localSearchPosts {
        publicIndexURL
        publicStoreURL
      }
    }
  `);
  const indexURL = localSearchPosts.publicIndexURL;
  const storeURL = localSearchPosts.publicStoreURL;
  const searchResults: Array<ResultProps> = useFlexSearch(
    query,
    indexURL,
    storeURL
  );
  const searchResultsPages = searchResults?.filter(
    (result) => result.nodeType === 'Page'
  );
  const searchResultsPosts = searchResults?.filter(
    (result) => result.nodeType === 'Post'
  );
  const searchResultsCaseStudies = searchResults?.filter(
    (result) => result.nodeType === 'CaseStudy'
  );

  // Search Results

  const [searchResultsOpen, setSearchResultsOpen] = useState(!!query);

  function closeSearch() {
    setMobileSearchOpen(false);
    setSearchResultsOpen(false);
    reset(['s']);
  }

  useOnClickOutside(searchForm, () => closeSearch());

  useEffect(() => {
    setSearchResultsOpen(true);
  }, [query]);

  return (
    <>
      <form action="/" ref={searchForm} onSubmit={(e) => e.preventDefault()}>
        <button
          type="submit"
          aria-label="search"
          onClick={handleClick}
          className={mobileSearchOpen ? 'active' : ''}
        >
          <MdSearch />
        </button>
        <input
          type="search"
          // placeholder="Search"
          required
          className={mobileSearchOpen ? 'active' : ''}
          {...register('s')}
          aria-hidden={!mobileSearchOpen}
          tabIndex={mobileSearchOpen ? 0 : -1}
        />
        <AnimatePresence>
          {searchResultsOpen && searchResults && (
            <motion.div
              className="search-results"
              initial="initial"
              animate="open"
              exit="exit"
              variants={{
                initial: {
                  opacity: 0,
                  rotateX: 10,
                },
                open: {
                  opacity: 1,
                  rotateX: 0,
                },
                exit: {
                  opacity: 0,
                  rotateX: 10,
                },
              }}
            >
              {searchResults.length > 0 && (
                <ul>
                  {searchResultsPages.length > 0 && (
                    <li className="search-results__heading">
                      <div className="search-results__heading__inner">
                        <h3>Page</h3>
                      </div>
                    </li>
                  )}
                  {searchResultsPages.length > 0 &&
                    searchResultsPages.map((result) => (
                      <>
                        <HeaderSearchResult
                          result={result}
                          closeSearch={closeSearch}
                        />
                      </>
                    ))}
                  {searchResultsPosts.length > 0 && (
                    <li className="search-results__heading">
                      <div className="search-results__heading__inner">
                        <h3>Post</h3>
                      </div>
                    </li>
                  )}
                  {searchResultsPosts.length > 0 &&
                    searchResultsPosts.map((result) => (
                      <>
                        <HeaderSearchResult
                          result={result}
                          closeSearch={closeSearch}
                        />
                      </>
                    ))}
                  {searchResultsCaseStudies.length > 0 && (
                    <li className="search-results__heading">
                      <div className="search-results__heading__inner">
                        <h3>Case Study</h3>
                      </div>
                    </li>
                  )}
                  {searchResultsCaseStudies.length > 0 &&
                    searchResultsCaseStudies.map((result) => (
                      <>
                        <HeaderSearchResult
                          result={result}
                          closeSearch={closeSearch}
                        />
                      </>
                    ))}
                </ul>
              )}
              {searchResults.length === 0 && (
                <div className="search-results__entry">
                  <p className="search-results__entry__inner">
                    <small>No results found.</small>
                  </p>
                </div>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </form>
    </>
  );
}
