import React, { createContext, useEffect, useState } from 'react';

interface ThemeContextProps {
  theme: string | undefined;
  setTheme: Function;
}

export const ThemeContext = createContext<ThemeContextProps>({
  theme: undefined,
  setTheme: () => {},
});

export function ThemeProvider({ children }: any) {
  const [theme, rawSetTheme] = useState<string | undefined>(undefined);

  useEffect(() => {
    const initialTheme: string | undefined = document.body.dataset.theme;
    rawSetTheme(initialTheme);

    // enable transition
    document.body.dataset.themeToggle = 'true';
  }, []);

  function setTheme(newTheme: string) {
    rawSetTheme(newTheme);
    window.localStorage.setItem('theme', newTheme);
  }

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  );
}
