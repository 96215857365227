import React from 'react';

interface ContactInfoProps {
  icon: any;
  text: string | Array<string>;
  link?: string;
}

export default function ContactInfo({ icon, text, link }: ContactInfoProps) {
  const label = Array.isArray(text)
    ? text
        .map<React.ReactNode>((t, i) => (
          <React.Fragment key={`${t}-${i}`}>{t}</React.Fragment>
        ))
        .reduce((prev, curr, i) => [prev, <br key={`r-${i}`} />, curr])
    : text;
  const info = link ? (
    <a href={link} target="_blank" rel="nooopener noreferrer">
      <span>{label}</span>
    </a>
  ) : (
    <span>{label}</span>
  );
  return (
    <div className="contact-info">
      {icon}
      {info}
    </div>
  );
}
