import React, { useEffect, useRef, useState } from 'react';
import { MdEmail } from 'react-icons/md';
import HamburgerMenu from './HamburgerMenu';
import Logo from './Logo';
import Menu from './Menu';
import MobileMenu from './MobileMenu';
import ContactInfo from './ContactInfo';
import Social from './Social';
import HeaderSearch from './HeaderSearch';
import ThemeToggle from './ThemeToggle';
import { useWindowSize } from '../hooks/useWindowSize';
import useOnClickOutside from '../hooks/useOnClickOutside';

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);

  const mobileMenuWrapperRef = useRef<HTMLDivElement>(null);

  const { width } = useWindowSize(); // Window Width

  useEffect(() => {
    if (menuOpen) {
      document.documentElement.classList.add('menu-open');
    } else {
      document.documentElement.classList.remove('menu-open');
    }

    if (menuOpen && width && width > 1024) {
      setMenuOpen(false);
    }
  }, [menuOpen, width]);

  useOnClickOutside(mobileMenuWrapperRef, () => setMenuOpen(false));

  return (
    <>
      <header className="header">
        <div className="header__top container">
          <div className="row">
            <div className="col-xs-12 col-md-8 col-md-offset-4 col-lg-7 col-lg-offset-5">
              <div className="header__top__inner">
                <div className="header__top__left push-left push-left-md-0">
                  <div
                    className="header__top__left__menu"
                    ref={mobileMenuWrapperRef}
                  >
                    <HamburgerMenu
                      isOpen={menuOpen}
                      menuClicked={() => setMenuOpen(!menuOpen)}
                      width={18}
                      height={12}
                      strokeWidth={2}
                      rotate={0}
                      // color="black"
                      borderRadius={0}
                      // animationDuration={0.5}
                      text="Menu"
                      closeText="Close"
                      className="hamburger-menu"
                    />
                    <MobileMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
                  </div>
                  <div className="header__top__left__icons">
                    <ThemeToggle id="theme-toggle-header" />
                    <Social />
                    <ContactInfo
                      icon={<MdEmail />}
                      text="info@peschdigital.com"
                      link="mailto:info@peschdigital.com"
                    />
                  </div>
                  <div className="header__top__left__logo">
                    <Logo />
                  </div>
                </div>
                <div className="header__top__right push-right">
                  <HeaderSearch />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header__bottom container hide-xs show-sm">
          <div className="header__bottom__inner">
            <div className="hide-xs show-sm">
              <Logo />
            </div>
            <div className="hide-xs show-md--flex middle-xs">
              <Menu />
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
